// shared styles
.btn,
.btn-flat {
    border: $button-border;
    border-radius: $button-radius;
    display: inline-block;
    height: $button-height;
    line-height: $button-height;
    padding: $button-padding;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent; // Gets rid of tap active state
}

// Disabled shared style
.btn.disabled,
.btn-floating.disabled,
.btn-large.disabled,
.btn-small.disabled,
.btn-flat.disabled,
.btn:disabled,
.btn-floating:disabled,
.btn-large:disabled,
.btn-small:disabled,
.btn-flat:disabled,
.btn[disabled],
.btn-floating[disabled],
.btn-large[disabled],
.btn-small[disabled],
.btn-flat[disabled] {
    pointer-events: none;
    background-color: $primary-color-dark !important;
    box-shadow: none;
    color: $button-disabled-color !important;
    cursor: default;
    &:hover {
        background-color: $primary-color-light !important;
        color: $button-disabled-color !important;
    }
}

// Shared icon styles
.btn,
.btn-floating,
.btn-large,
.btn-small,
.btn-flat {
    font-size: $button-font-size;
    outline: 0;
    i {
        font-size: $button-icon-font-size;
        line-height: inherit;
    }
}

// Shared focus button style
.btn,
.btn-floating {
    &:focus {
        background-color: darken($button-raised-background, 10%);
    }
}

// Raised Button
.btn {
    text-decoration: none;
    color: $button-raised-color;
    background-color: $button-raised-background;
    text-align: center;
    letter-spacing: 0.5px;
    @extend .z-depth-1;
    transition: background-color 0.2s ease-out;
    cursor: pointer;
    &:hover {
        background-color: $button-raised-background-hover;
        @extend .z-depth-1-half;
    }
}

// Floating button
.btn-floating {
    &:hover {
        background-color: $button-floating-background-hover;
        @extend .z-depth-1-half;
    }
    &:before {
        border-radius: 0;
    }
    &.btn-large {
        &.halfway-fab {
            bottom: -$button-floating-large-size / 2;
        }
        width: $button-floating-large-size;
        height: $button-floating-large-size;
        padding: 0;
        i {
            line-height: $button-floating-large-size;
        }
    }

    &.btn-small {
        &.halfway-fab {
            bottom: -$button-floating-small-size / 2;
        }
        width: $button-floating-small-size;
        height: $button-floating-small-size;
        i {
            line-height: $button-floating-small-size;
        }
    }

    &.halfway-fab {
        &.left {
            right: auto;
            left: 24px;
        }
        position: absolute;
        right: 24px;
        bottom: -$button-floating-size / 2;
    }
    display: inline-block;
    color: $button-floating-color;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: $button-floating-size;
    height: $button-floating-size;
    line-height: $button-floating-size;
    padding: 0;
    background-color: $button-floating-background;
    border-radius: $button-floating-radius;
    @extend .z-depth-1;
    transition: background-color 0.3s;
    cursor: pointer;
    vertical-align: middle;
    i {
        width: inherit;
        display: inline-block;
        text-align: center;
        color: $button-floating-color;
        font-size: $button-large-icon-font-size;
        line-height: $button-floating-size;
    }
}

// button fix
button.btn-floating {
    border: $button-border;
}

// Fixed Action Button
.fixed-action-btn {
    &.active {
        ul {
            visibility: visible;
        }
    }

    // Directions
    &.direction-left,
    &.direction-right {
        padding: 0 0 0 15px;
        ul {
            text-align: right;
            right: 64px;
            top: 50%;
            transform: translateY(-50%);
            height: 100%;
            left: auto;
            /*width 100% only goes to width of button container */
            width: 500px;
            li {
                display: inline-block;
                margin: 7.5px 15px 0 0;
            }
        }
    }
    &.direction-right {
        padding: 0 15px 0 0;
        ul {
            text-align: left;
            direction: rtl;
            left: 64px;
            right: auto;
            li {
                margin: 7.5px 0 0 15px;
            }
        }
    }
    &.direction-bottom {
        padding: 0 0 15px 0;
        ul {
            top: 64px;
            bottom: auto;
            display: flex;
            flex-direction: column-reverse;
            li {
                margin: 15px 0 0 0;
            }
        }
    }
    &.toolbar {
        &.active {
            & > a i {
                opacity: 0;
            }
        }
        padding: 0;
        height: $button-floating-large-size;
        ul {
            display: flex;
            top: 0;
            bottom: 0;
            z-index: 1;
            li {
                flex: 1;
                display: inline-block;
                margin: 0;
                height: 100%;
                transition: none;
                a {
                    display: block;
                    overflow: hidden;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    box-shadow: none;
                    color: #fff;
                    line-height: $button-floating-large-size;
                    z-index: 1;
                    i {
                        line-height: inherit;
                    }
                }
            }
        }
    }
    position: fixed;
    right: 23px;
    bottom: 23px;
    padding-top: 15px;
    margin-bottom: 0;
    z-index: 997;
    ul {
        left: 0;
        right: 0;
        text-align: center;
        position: absolute;
        bottom: 64px;
        margin: 0;
        visibility: hidden;
        li {
            margin-bottom: 15px;
        }
        a.btn-floating {
            opacity: 0;
        }
    }
    .fab-backdrop {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: $button-floating-size;
        height: $button-floating-size;
        background-color: $button-floating-background;
        border-radius: $button-floating-radius;
        transform: scale(0);
    }
}

// Flat button
.btn-flat {
    box-shadow: none;
    background-color: transparent;
    color: $button-flat-color;
    cursor: pointer;
    transition: background-color 0.2s;
    &:focus,
    &:hover {
        box-shadow: none;
    }
    &:focus {
        background-color: rgba(0, 0, 0, 0.1);
    }
    &.disabled,
    &.btn-flat[disabled] {
        background-color: transparent !important;
        color: $button-flat-disabled-color !important;
        cursor: default;
    }
}

// Large button
.btn-large {
    @extend .btn;
    height: $button-large-height;
    line-height: $button-large-height;
    font-size: $button-large-font-size;
    padding: 0 28px;

    i {
        font-size: $button-large-icon-font-size;
    }
}

// Small button
.btn-small {
    @extend .btn;
    height: $button-small-height;
    line-height: $button-small-height;
    font-size: $button-small-font-size;
    i {
        font-size: $button-small-icon-font-size;
    }
}

// Block button
.btn-block {
    display: block;
}
