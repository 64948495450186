nav {
    color: $navbar-font-color;
    background-color: $navbar-background-color;
    width: 100%;

    // @extend .z-depth-1;

    .nav-wrapper {
        min-height: $navbar-height-mobile;
        height: auto;
    }

    a.sidenav-trigger {
        margin: 0 6px;
    }

    @media #{$large-and-up} {
        a.sidenav-trigger {
            display: none;
        }
    }



    // Submenu
    li {

        position: relative;

        .submenu {
            position: absolute;
            z-index: 999;
            opacity: 0;
            left: -20px;
            top: 80px;
            padding: 8px 0;
            display: none;
            background-color: #fff;
            border-top: solid $primary-color 2px;

            &:before {
                content: "";
                position: absolute;
                top: -14px;
                left: 50%;
                transform: translateX(-50%);
                margin-right: -12px;
                border-bottom: 0px solid #d0c194;
                border-top: 6px solid transparent;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 6px solid $primary-color;
                transition: all 0.3s ease-in-out 0s;
            }

            li {

                &:first-child {
                    display: none;
                }

                width: 160px;
                line-height: 24px;
                padding: 8px 0;
            }

            &.en {
                li {
                    width: 200px;
                }
            }
        }

        &:hover {
            .submenu {
                display: block;
                @include fadeInDown($duration: 1s,
                    $function: ease,
                    $fill: forwards);

                &:before {
                    border-bottom: 6px solid $primary-color;
                }
            }
        }
    }



    // Logo
    .brand-logo {

        @media #{$medium-and-down} {
            position: initial;
        }

        padding:1rem;
        width: $logo-width*1px;

        @media #{$small-and-down} {
            width: rem($logo-width* .8);
        }

        font-size: 16px;
        background: url('../images/logo.png') no-repeat left top;
        background-size: contain !important;

        //居中
        &.center {
            left: 50%;
            transform: translateX(-50%);
        }

        @media #{$medium-and-down} {
            background: url('../images/logo_small.png') no-repeat left center;

            &.left {
                left: 0.5rem;
            }

            &.right {
                right: 0.5rem;
                left: auto;
            }
        }

        &.right {

            font-size: 0;
            right: 0.5rem;
            padding: 0;
        }
    }


    // Navbar Links
    ul {
        &>li {
            @media #{$small-and-down} {
                a {
                    padding: 0 3px;
                }
            }

            &.active {

                &>a {
                    color: $primary-color;
                }
            }


            a {
                color: $navbar-font-color;
            }
        }

    }

    .input-field {

        input {

            &:focus,
            &[type=text]:valid,
            &[type=password]:valid,
            &[type=email]:valid,
            &[type=url]:valid,
            &[type=date]:valid {
                border-bottom: 1px solid $standard-ancent;
                box-shadow: none;
            }
        }
    }
}

.navbar-fixed {
    nav {
        background-color: $body-color;
        @extend .z-depth-1;
    }
}
