$baseFontSize:16;

//使用在font-size上面
@mixin font-size-rem($px) {
    font-size: $px*1px;
    font-size: ($px / $baseFontSize) * 1rem;
}

//一般px轉換rem
@function rem($pixels, $context: $baseFontSize) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return $pixels / $context * 1rem;
}




//font-size:rem(12);
