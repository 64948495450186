// Flex 外容器屬性：

// display  -- flex | inline-flex
// flex-direction -- row| row-reverse | column | column-reverse;
// flex-wrap -- nowrap | wrap | wrap-reverse;
// justify-content: flex-start | flex-end | center | space-between | space-around;

// align-items


// Flex 內元件屬性：
// flex
// flex-grow
// flex-shrink
// flex-basis
// order
// align-self

@mixin flex($display: 'flex', $direction:'row', $wrap:'wrap', $justify:'center', $items:'center') {
    display: $display;
    flex-direction: $direction;
    flex-wrap: $wrap;
    justify-content: $justify;
    align-items: $items;
}
