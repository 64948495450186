html {
    scroll-behavior: smooth;
}

img.lazy {
    visibility: hidden;
}

a {
    color: $primary-color;

    &:hover {
        color: $primary-color-light;
    }
}

.text-primay {
    color: $primary-color;
}

ul li a {
    cursor: pointer;
}

.text-aligin-center {
    text-align: center;
}



.v-align-container {
    display: table;

    .v-align-center {
        display: table-cell;
        vertical-align: middle;
    }
}

.wrapper {
    margin-top: -4rem;
    padding: 1rem;
    background-color: #fff;

    @media #{$medium-and-up} {
        padding: 3rem;
    }

    .page-title {

        //內容頁標題
        h4 {
            color: $light-ancent;
            font-weight: normal;
        }

        a {
            @include font-size-rem(16);
            line-height: rem($h4-fontsize*2.2);
        }

        @media #{$medium-and-up} {
            padding-bottom: 2rem;
        }
    }

    .body {
        padding-top: 1rem;
    }
}

.content {
    padding: 3rem 0;
}

.card {
    .btn-flat {
        position: absolute;
        left: 8px;
        bottom: -20px;
        opacity: 0;
        transition: all .2s .2s ease;
    }

    &:hover {
        .btn-flat {
            bottom: -10px;
            opacity: 1;
            transform: scaleX(1);
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 120vh;
    background-color: rgba(256, 256, 256, .8);
    z-index: 997;

    .preloader-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
}
