.flex-container-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.flex-row-center {
    @include flex('flex', 'row', 'wrap', 'center', 'center');
}
