.banner {
    &.bgimg {
        background: url('../images/banner.png') no-repeat top center;

        @media #{$large-and-up} {
            background-position: center -80px;
        }

        @media #{$medium-and-up} {
            background-position: center -40px;
        }

        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
    }

    .title {
        @media #{$medium-and-down} {
            padding: 4rem 0 6rem;
        }

        @media #{$small-and-down} {
            .large {
                color: #fff;
            }

            .small {
                color: #fff;
            }
        }

        padding: 6rem 0 8rem;
        margin-left: 3rem;
        font-style: italic;


        .large {
            color: #3c4653;
            font-weight: 500;
            letter-spacing: .5rem;
        }

        .small {
            color: lighten(#3c4653, 50%);
        }
    }

}
