/* #Footer
================================================== */

.footer {
    padding: rem(100) 0 rem(80) 0;
    background-color: $footer-background-color;

    h6 {
        color: $footer-text-color;
        padding: 10px 0;
    }

    h5 {
        color: $footer-text-light-color;
    }

    .notice {
        color: $primary-color
    }

    .image_wrapper {
        width: 137px;
        padding: 10px 0;
    }

    ul li,
    .footer-infor {
        @include font-size-rem(16);
        line-height: rem(40);
        color: $footer-text-light-color;

        a {
            color: $footer-text-light-color;

            &:hover {
                color: $primary-color
            }
        }
    }

    //social media
    .social-menu-circle {
        li {
            display: inline-block;
            padding-right: 5px;
        }
    }
}

.footer-copy {
    background-color: $footer-background-color;
    text-align: center;
    color: $footer-text-light-color;
    border-top: 1px solid rgba(255, 255, 255, .1);

    a {
        color: $footer-text-light-color;
        cursor: pointer;

        &:hover {
            color: $footer-text-color;
        }
    }

    .copyright {
        padding: 24px 0;
    }
}
