%body {
    @include font-size-rem($body-fontsize);
    line-height: rem(40);
    color: $light-ancent;
}

.body {
    @extend %body;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 1.5rem 0;
        color: $standard-ancent;
    }
}


.body-titile {
    @include font-size-rem($body-fontsize);
    line-height: rem(20);
    color: $light-ancent;
}
