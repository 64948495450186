a {
    text-decoration: none;
}

html {
    line-height: 1.5;

    @media only screen and (min-width: 0) {
        font-size: 14px;
    }

    @media only screen and (min-width: $medium-screen) {
        font-size: 14.5px;
    }

    @media only screen and (min-width: $large-screen) {
        font-size: 15px;
    }

    font-family: $font-stack;
    font-weight: normal;
    color: $off-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    line-height: 1.3;
}

// Header Styles
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    font-weight: inherit;
}

h1 {
    @include font-size-rem($h1-fontsize);
    line-height: 90%;
    margin: (rem($h1-fontsize) / 1.5) 0 (rem($h1-fontsize) / 2.5) 0;
}

h2 {
    @include font-size-rem($h2-fontsize);
    line-height: 120%;
    margin: (rem($h2-fontsize) / 1.5) 0 (rem($h2-fontsize) / 2.5) 0;
}

h3 {
    @include font-size-rem($h3-fontsize);
    line-height: 120%;
}

h4 {
    @include font-size-rem($h4-fontsize);
    line-height: 120%;
    margin: (rem($h4-fontsize) / 1.5) 0 (rem($h4-fontsize) / 2.5) 0;
}

h5 {
    @include font-size-rem($h5-fontsize);
    line-height: 150%;
    margin: (rem($h5-fontsize) / 1.5) 0 (rem($h5-fontsize) / 2.5) 0;
}

h6 {
    @include font-size-rem($h6-fontsize);
    line-height: 120%;
    margin: (rem($h6-fontsize) / 1.5) 0 (rem($h6-fontsize) / 2.5) 0;
}

// Text Styles
em {
    font-style: italic;
}

strong {
    font-weight: 500;
}

small {
    font-size: 75%;
}

.light {
    font-weight: 300;
}

.thin {
    font-weight: 200;
}


.flow-text {
    $i: 0;

    @while $i <=$intervals {
        @media only screen and (min-width: 360 + ($i * $interval-size)) {
            font-size: 1.2rem * (1 + (.02 * $i));
        }

        $i: $i+1;
    }

    // Handle below 360px screen
    @media only screen and (max-width: 360px) {
        font-size: 1.2rem;
    }
}
