.btn-line {
    position: relative;
    color: $primary-color;

    &:after {
        content: "";
        bottom: 0;
        right: 0;
        position: absolute;
        background-color: $primary-color;
        width: 0%;
        height: 1px;
        transition: all .3s ease;
    }

    &:before {
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        background-color: $primary-color;
        width: 0%;
        height: 1px;
        transition: all .3s ease;
    }

    &:hover {
        &:after {
            width: 100%;
        }

        &:before {
            width: 100%;
        }

        span::before {
            height: 100%;
        }

        span::after {
            height: 100%;
        }
    }

    span::before {
        content: "";
        bottom: 0;
        left: 0;
        position: absolute;
        background-color: $primary-color;
        width: 1px;
        height: 0%;
        transition: all .3s ease;
    }

    span::after {
        content: "";
        bottom: 0;
        right: 0;
        position: absolute;
        background-color: $primary-color;
        width: 1px;
        height: 0%;
        transition: all .3s ease;
    }
}
