body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.text-warning {
    color: #a94442;
}

//login

#login {
    flex-direction: row;

    label {
        font-size: 1.5rem;
        top: -10px;
    }

    .content {
        flex: 1;
    }

    .logo-img {
        text-align: center;
        padding: 0 30px 0 0;
    }

    .login-box {
        width: 70%;
        margin: 0 auto;
    }
    .line {
        margin: 30px 0;
        &::before,
        &::after {
            content: "";
            width: 30%;
            height: 1px;
            display: inline-block;
            margin-bottom: 4px;
            background-color: $primary-color;
        }
        span {
            padding: 30px;
        }
    }
    .social-login {
        text-align: center;
        a {
            width: 60px;
            height: 60px;
            border-radius: 20%;
            display: inline-flex;
            transition: all 2s;
            &:hover {
                box-shadow: 2px 3px 15px 5px rgba(44, 88, 45, 0.15);
            }
        }
        a + a {
            margin-left: 15px;
        }
    }
    .text-primary {
        color: $primary-color;
    }
}

.sidenav-wrapper {
    .side-nav {
        transition: all 0.25s;
    }

    .sidenav-trigger {
        position: absolute;
        z-index: 1200;
        top: 34px;
        left: 340px;
    }
}

.sidenav .user-view .name {
    display: inline-block;
}

.btn-flat {
    border: solid 1px #343434;
    margin: 0.5rem;
}

main.content {
    margin-left: 340px;
}

//背景主圖
body {
    background: url("../images/header_bg.gif") center top no-repeat #fafbff;
}

//logo
.brand-logo {
    font-size: 0;
    padding: 1rem 0;
    background: url("../images/logo.png") center top no-repeat #fafbff;

    @media #{$medium-and-down} {
        height: initial;
    }
}

label {
    font-size: 1rem;
    color: #000000;
}

@media (min-width: 993px) {
    main,
    footer {
        padding-left: 300px;
    }
}

i.pink-item {
    color: #f4425c !important;
}

form.sidebar-form {
    margin: 5px;
}

div.page-announce {
    min-height: 175px;
    height: auto;
    background-color: #f4425c;
    padding: 40px;
    margin-bottom: 10px;
}

h1.page-announce-text {
    font-family: "Open Sans", sans-serif;
    font-weight: 100;
    font-size: 56px;
    color: #fff;
    line-height: 0.5em;
    margin-left: 50px;
}

main {
    flex: 1 0 auto;
}

.material-tooltip {
    display: hidden;
}

.font-primary-color {
    color: $primary-color;
}

.main-pic {
    background: url("../images/main-pic.png") no-repeat 50% 50%;
    background-size: cover;
}

.bg-aqua {
    background-color: #00c0ef !important;
}

.bg-green {
    background-color: #00a65a !important;
}

.bg-yellow {
    background-color: #f39c12 !important;
}

.bg-red {
    background-color: #dd4b39 !important;
}

.text-underline {
    text-decoration: underline;
}

.small-box {
    border-radius: 2px;
    position: relative;
    display: block;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

    > .inner {
        padding: 10px;
    }

    h3 {
        font-size: 38px;
        font-weight: bold;
        margin: 0 0 10px 0;
        white-space: nowrap;
        padding: 0;
        color: #fff;
    }

    p {
        color: #fff;
    }

    .icon {
        -webkit-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        position: absolute;
        top: -10px;
        right: 10px;
        z-index: 0;
        font-size: 90px;
        color: rgba(0, 0, 0, 0.15);
    }

    > .small-box-footer {
        position: relative;
        text-align: center;
        padding: 3px 0;
        color: rgba(255, 255, 255, 0.8);
        display: block;
        z-index: 10;
        background: rgba(0, 0, 0, 0.1);
        text-decoration: none;
    }
}

.page-footer {
    padding-top: 0 !important;
    background-color: #f4425c;
    margin-top: 20px;
}

.footer-copyright {
    background-color: inherit !important;
}

.autocomplete-suggestions {
    border: 1px solid #999;
    width: 210px !important;
    background: #fff;
    max-height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    position: fixed;
    width: 290px !important;
    top: 161px;
    left: 11px;
    max-height: 300px;
    z-index: 20;
}

.autocomplete-suggestion {
    padding: 10px 5px;
    width: 300px !important;
    font-size: 1.2em;
    white-space: nowrap;
}

keygroup {
    margin-left: 5px;
}

.getusr {
    width: 500px;
}

.text-thin {
    font-weight: 100 !important;
}

a.button-collapse {
    color: #fff;
}

div.quick-links a {
    width: 100%;
    margin: 5px;
}

.alert-success {
    background-color: #dff0d8;
    border-color: #d0e9c6;
    color: #3c763d;
}

.alert {
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.alert-info {
    background-color: #d9edf7;
    border-color: #bcdff1;
    color: #31708f;
}

.alert-warning {
    background-color: #fcf8e3;
    border-color: #faf2cc;
    color: #8a6d3b;
}

.alert-danger {
    background-color: #f2dede;
    border-color: #ebcccc;
    color: #a94442;
}

#chatInfo p {
    font-size: 20px !important;
    font-weight: 300 !important;
}

.custom-responsive {
    display: block;
    overflow-x: auto;
}

@media (max-width: 320px) {
    .page-announce {
        min-height: 40px;
        height: auto;
    }

    .page-announce-text {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .page-announce {
        min-height: 50px;
        height: auto;
    }

    .page-announce-text {
        font-size: 24px;
    }
}

@media (max-width: 768px) {
    .getusr {
        margin-left: 0;
        width: 300px;
    }

    .page-announce-text {
        font-size: 36px;
    }

    .page-announce {
        padding: 20px;
    }
}

@media (max-width: 992px) {
    div.page-announce {
        min-height: 60px;
        height: auto;
        padding: 10px;
    }

    h1.page-announce-text {
        font-size: 32px;
    }
}
