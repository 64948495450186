.sidenav {

    @extend .z-depth-1;

    .collapsible-body {
        padding: 0 0 0 15px;

        li {
            &:first-child {
                display: none;
            }
        }
    }
}

.subheader {
    &:hover {
        background-color: transparent;
    }

    cursor: initial;
    pointer-events: none;
    color: rgba(0, 0, 0, .54);
    font-size: $sidenav-font-size;
    font-weight: 500;
    line-height: $sidenav-line-height;
}
