.collapsible {
    // border-top: 1px solid $collapsible-border-color;
    // border-right: 1px solid $collapsible-border-color;
    // border-left: 1px solid $collapsible-border-color;
    margin: $element-top-margin 0 $element-bottom-margin 0;
    // @extend .z-depth-1;
}

.collapsible-header {
    &:focus {
        outline: 0
    }

    display: flex;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    line-height: 1.5;
    padding: 1rem;
    background-color: $collapsible-header-color;
    border-bottom: 1px solid $collapsible-border-color;

    i {
        width: 2rem;
        font-size: 1.6rem;
        display: inline-block;
        text-align: center;
        margin-right: 1rem;
        margin-top: 1.2rem;
    }
}

.keyboard-focused .collapsible-header:focus {
    background-color: #eee;
}

.collapsible-body {
    display: none;
    border-bottom: 1px solid $collapsible-border-color;
    box-sizing: border-box;
    padding: 2rem;
}

// Sidenav collapsible styling
.sidenav,
.sidenav.fixed {

    .collapsible {
        border: none;
        box-shadow: none;

        li {
            padding: 0;
        }
    }

    .collapsible-header {
        background-color: transparent;
        border: none;
        line-height: inherit;
        height: inherit;
        padding: 0 $sidenav-padding;

        &:hover {
            background-color: rgba(0, 0, 0, .05);
        }

        i {
            line-height: inherit;
        }
    }

    .collapsible-body {
        border: 0;
        background-color: $collapsible-header-color;

        li a {
            padding: 0 (7.5px + $sidenav-padding) 0 (15px + $sidenav-padding);
        }
    }

}

// Popout Collapsible

.collapsible.popout {
    border: none;
    box-shadow: none;

    >li {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        // transform: scaleX(.92);
        margin: 0 24px;
        transition: margin .35s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    }

    >li.active {
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
        margin: 16px 0;
        // transform: scaleX(1);
    }
}
